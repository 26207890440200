/**
 * ! import dependencies
 */

import './style.css'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import { Raycaster, sRGBEncoding } from 'three'
import * as dat from 'dat.gui'
import 'jquery'
// import Stats from '../node_modules/stats.js'

// console.log(Stats);
// /**
//  * ! monitoring
//  */
//  const stats = new Stats()
//  stats.showPanel(0)
//  document.body.appendChild(stats.dom)



setTimeout(function() {
    $('.loading_screen').fadeOut(200); },10);


/**
 * ! Debug
 */

const gui = new dat.GUI({width: 500})
gui.hide()

/**
 * ! Textures
 */
//we first need a loadingmanager and a textureloader
const loadingManager = new THREE.LoadingManager()

const textureLoader = new THREE.TextureLoader( loadingManager )

//we load in the textures, and apply nearest neighbour filter to the textures

// const diffuse = textureLoader.load('./bicycle/earth/mediated/voorlopig/testing_0.png')
// const diffuse = textureLoader.load('./bicycle/earth/mediated/voorlopig/a.jpg')
 






/**
* ! check for mobile
*/

// vary texture according to resolution

// const isMobileTexture = window.matchMedia("only screen and (max-width: 1000px)").matches;
// if (isMobileTexture) {
    
//     console.log("is mobile Texture")
//     var diffuse = textureLoader.load('./bicycle/earth/mediated/voorlopig/specular_dither_0.png')
    
//     diffuse.generateMipmaps= false
//     diffuse.minFilter = THREE.NearestFilter
//     diffuse.magFilter = THREE.NearestFilter
    
    
  
// }
// else{
//     console.log("is NOT mobile Texture")
    
    
    
// }
const diffuse = textureLoader.load('a.jpg')
    
    diffuse.generateMipmaps= false
    diffuse.minFilter = THREE.NearestFilter
    diffuse.magFilter = THREE.NearestFilter
const specular = textureLoader.load('specular.gif')
specular.generateMipmaps= false
specular.minFilter = THREE.NearestFilter
specular.magFilter = THREE.NearestFilter

/**
 * ! scene
 */
const scene = new THREE.Scene()


const material = new THREE.MeshStandardMaterial()
material.map = diffuse
material.color = new THREE.Color('white')

material.roughnessMap = specular

material.bumpMap= specular


material.bumpScale=0.0002
material.side = 0



const materialB = new THREE.MeshStandardMaterial()
materialB.transparent = true
materialB.color = new THREE.Color('#4B4341')
materialB.side = 1
materialB.opacity = 0.6

const basic = new THREE.MeshBasicMaterial()
basic.map = diffuse
basic.roughnessMap = specular


//add mesh = geometry + material

const sphere_geo = new THREE.Mesh(
    new THREE.SphereBufferGeometry(1,50,50), 
    material
)

//add secondary mesh = new geo + new material
const outer_sphere_geo = new THREE.Mesh(
    new THREE.SphereBufferGeometry(1.0035,50,50),
    materialB
)
//add to the scene
scene.add(sphere_geo, outer_sphere_geo)


/**
 * ! points of interest
 */

//add a raycaster from the camera to the points
const raycaster = new Raycaster()

//add the point positions and index
const points = [
    {
        //0
        position: new THREE.Vector3(), 
        scale: new THREE.Vector2(1,1),
        startPosition: new THREE.Vector3(-0.023,0.693,1.015),
        a:5,
        element: document.querySelector('.point-0')
    },
    {
        //1
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.024,0.66,0.999),
        element: document.querySelector('.point-1')
    },
    {
        //2
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.036,0.662,0.999),
        element: document.querySelector('.point-2')
    },
    {
        //3
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.084,0.656,1),
        element: document.querySelector('.point-3')
    },
    {
        //4
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.026,0.65,0.999),
        element: document.querySelector('.point-4')
    },
    {
        //5
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.102,0.645,0.994),
        element: document.querySelector('.point-5')
    },
    {
        //6
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.387,6.17,0.59),
        element: document.querySelector('.point-6')
    },
    {
        //7
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.446,5.836,0.569),
        element: document.querySelector('.point-7')
    },
    {
        //8
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.181,0.401,0.984),
        element: document.querySelector('.point-8')
    },
    {
        //9
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.112,0.715,0.996),
        element: document.querySelector('.point-9')
    },
    {
        //10
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.586,5.833,0.533),
        element: document.querySelector('.point-10')
    },
    {
        //11
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.115,0.699,0.993),
        element: document.querySelector('.point-11')
    },
    {
        //12
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.066,0.679,0.999),
        element: document.querySelector('.point-12')
    },
    {
        //13
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.346,0.071,0.598),
        element: document.querySelector('.point-13')
    },
    {
        //14
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.755,5.957,0.8),
        element: document.querySelector('.point-14')
    },
    {
        //15
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.756,0.329,0.798),
        element: document.querySelector('.point-15')
    },
    {
        //16
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.053,0.765,0.998),
        element: document.querySelector('.point-16')
    },
    {
        //17
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.34,5.911,0.6),
        element: document.querySelector('.point-17')
    },
    {
        //18
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.051,0.735,1),
        element: document.querySelector('.point-18')
    },
    {
        //19
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.545,5.91,0.544),
        element: document.querySelector('.point-19')
    },
    {
        //20
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.009,0.684,1),
        element: document.querySelector('.point-20')
    },
    {
        //21
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.096,0.612,0.997),
        element: document.querySelector('.point-21')
    },
    {
        //22
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.014,0.671,1),
        element: document.querySelector('.point-22')
    },
    {
        //23
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.264,0.103,0.623),
        element: document.querySelector('.point-23')
    },
    {
        //24
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.328,0.115,0.602),
        element: document.querySelector('.point-24')
    },
    {
        //25
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.126,0.647,0.992),
        element: document.querySelector('.point-25')
    },
    {
        //26
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.076,0.754,0.997),
        element: document.querySelector('.point-26')
    },
    {
        //27
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.03,0.663,1),
        element: document.querySelector('.point-27')
    },
    {
        //28
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.19,6.058,0.666),
        element: document.querySelector('.point-28')
    },
    {
        //29
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.035,0.754,1),
        element: document.querySelector('.point-29')
    },
    {
        //30
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.04,0.584,0.999),
        element: document.querySelector('.point-30')
    },
    {
        //31
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.189,2.868,0.983),
        element: document.querySelector('.point-31')
    },
    {
        //32
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.068,0.682,0.998),
        element: document.querySelector('.point-32')
    },
    {
        //33
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.947,5.718,0.729),
        element: document.querySelector('.point-33')
    },
    {
        //34
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.021,6.076,0.699),
        element: document.querySelector('.point-34')
    },
    {
        //35
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.106,0.278,0.671),
        element: document.querySelector('.point-35')
    },
    {
        //36
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.127,0.212,0.783),
        element: document.querySelector('.point-36')
    },
    {
        //37
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.055,0.688,1.003),
        element: document.querySelector('.point-37')
    },
    {
        //38
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.025,0.689,1),
        element: document.querySelector('.point-38')
    },
    {
        //39
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.051,0.676,1.007),
        element: document.querySelector('.point-39')
    },
    {
        //40
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.115,3.14,0.994),
        element: document.querySelector('.point-40')
    },



    {
        //41
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.139,0.643,0.99),
        element: document.querySelector('.point-41')
    },
    {
        //42
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.378,6.269,0.588),
        element: document.querySelector('.point-42')
    },
    {
        //43
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(1.008,5.911,0.704),
        element: document.querySelector('.point-43')
    },
    {
        //44
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.045,0.549,1),
        element: document.querySelector('.point-44')
    },
    {
        //45
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.162,0.08,0.652),
        element: document.querySelector('.point-45')
    },
    {
        //46
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.182,0.881,0.984),
        element: document.querySelector('.point-46')
    },
    {
        //47
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.086,0.804,0.996),
        element: document.querySelector('.point-47')
    },
    {
        //48
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(1.61,0.651,0.528),
        element: document.querySelector('.point-48')
    },
    {
        //49
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.087,0.832,0.995),
        element: document.querySelector('.point-49')
    },
    {
        //50
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.186,0.802,0.983),
        element: document.querySelector('.point-50')
    },
    {
        //51
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.326,1.056,0.95),
        element: document.querySelector('.point-51')
    },
    {
        //52
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.179,0,0.647),
        element: document.querySelector('.point-52')
    },
    {
        //53
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.62,3.898,0.849),
        element: document.querySelector('.point-53')
    },
    {
        //54
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.048,0.667,0.999),
        element: document.querySelector('.point-54')
    },
    {
        //55
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(1.042,5.908,0.693),
        element: document.querySelector('.point-55')
    },
    {
        //56
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.634,3.912,0.844),
        element: document.querySelector('.point-56')
    },
    {
        //57
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.984,0.016,0.714),
        element: document.querySelector('.point-57')
    },
    {
        //58
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.009,0.634,0.995),
        element: document.querySelector('.point-58')
    },
    {
        //59
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.989,0,0.712),
        element: document.querySelector('.point-59')
    },
    {
        //60
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.37,5.911,0.599),
        element: document.querySelector('.point-60')
    },
    {
        //61
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.019,0.664,0.999),
        element: document.querySelector('.point-61')
    },
    {
        //62
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.844,0,0.765),
        element: document.querySelector('.point-62')
    },
    {
        //63
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.009,0.66,1),
        element: document.querySelector('.point-63')
    },
    {
        //64
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.64,3.917,0.841),
        element: document.querySelector('.point-64')
    }, 
    {
        //65
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.024,0.655,0.999),
        element: document.querySelector('.point-65')
    },   
    {
        //66
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.033,0.542,1),
        element: document.querySelector('.point-66')
    }, 
    {
        //67
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(0.56,3.922,0.874),
        element: document.querySelector('.point-67')
    }, 
    {
        //68
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-1.33,0.08,0.603),
        element: document.querySelector('.point-68')
    }, 
    {
        //69
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.041,0.684,0.999),
        element: document.querySelector('.point-69')
    }, 
    {
        //70
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.031,0.676,0.999),
        element: document.querySelector('.point-70')
    }, 
    {
        //71
        position: new THREE.Vector3(), 
        startPosition: new THREE.Vector3(-0.031,0.676,80),
        element: document.querySelector('.point-71')
    }, 



]

/**
    * ! Tweaks
    */

const folder_0 = gui.addFolder('0 my home')
folder_0.add(points[0].startPosition,'x').min(-5).max(5).step(0.001)
folder_0.add(points[0].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_0.add(points[0].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_1 = gui.addFolder('1 kuleuven')
folder_1.add(points[1].startPosition,'x').min(-2).max(2).step(0.001)
folder_1.add(points[1].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_1.add(points[1].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_2 = gui.addFolder('2 vti')
folder_2.add(points[2].startPosition,'x').min(-2).max(2).step(0.001)
folder_2.add(points[2].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_2.add(points[2].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_3 = gui.addFolder('3 the bartlett')
folder_3.add(points[3].startPosition,'x').min(-2).max(2).step(0.001)
folder_3.add(points[3].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_3.add(points[3].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_4 = gui.addFolder('4 marissstella')
folder_4.add(points[4].startPosition,'x').min(-2).max(2).step(0.001)
folder_4.add(points[4].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_4.add(points[4].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_5 = gui.addFolder('5 Bpro RC1, RC19 & RC20')
folder_5.add(points[5].startPosition,'x').min(-2).max(2).step(0.001)
folder_5.add(points[5].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_5.add(points[5].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_6 = gui.addFolder('6 Tennessee - Knoxville')
folder_6.add(points[6].startPosition,'x').min(-2).max(2).step(0.001)
folder_6.add(points[6].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_6.add(points[6].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_7 = gui.addFolder('7 317 Artificial i')
folder_7.add(points[7].startPosition,'x').min(-2).max(2).step(0.001)
folder_7.add(points[7].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_7.add(points[7].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_8 = gui.addFolder('8 Workshop Bartlett UCL')
folder_8.add(points[8].startPosition,'x').min(-2).max(2).step(0.001)
folder_8.add(points[8].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_8.add(points[8].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_9 = gui.addFolder('9 DesignMorphine (TU Wien')
folder_9.add(points[9].startPosition,'x').min(-2).max(2).step(0.001)
folder_9.add(points[9].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_9.add(points[9].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_10 = gui.addFolder('10 T4T lab')
folder_10.add(points[10].startPosition,'x').min(-2).max(2).step(0.001)
folder_10.add(points[10].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_10.add(points[10].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_11 = gui.addFolder('11 Blender workshop in Antwerp')
folder_11.add(points[11].startPosition,'x').min(-2).max(2).step(0.001)
folder_11.add(points[11].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_11.add(points[11].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_12 = gui.addFolder('12 Interview D2 Inspiration Series')
folder_12.add(points[12].startPosition,'x').min(-2).max(2).step(0.001)
folder_12.add(points[12].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_12.add(points[12].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_13 = gui.addFolder('13 Interview VTM.')
folder_13.add(points[13].startPosition,'x').min(-2).max(2).step(0.001)
folder_13.add(points[13].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_13.add(points[13].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_14 = gui.addFolder('14 Interview CGarchitect.')
folder_14.add(points[14].startPosition,'x').min(-2).max(2).step(0.001)
folder_14.add(points[14].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_14.add(points[14].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_15 = gui.addFolder('15 Interview Unbuilt.')
folder_15.add(points[15].startPosition,'x').min(-2).max(2).step(0.001)
folder_15.add(points[15].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_15.add(points[15].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_16 = gui.addFolder('16 Venice Biennale')
folder_16.add(points[16].startPosition,'x').min(-2).max(2).step(0.001)
folder_16.add(points[16].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_16.add(points[16].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_17 = gui.addFolder('17 Lecture @Austin')
folder_17.add(points[17].startPosition,'x').min(-2).max(2).step(0.001)
folder_17.add(points[17].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_17.add(points[17].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_18 = gui.addFolder('18 Speaker D2 Conference Vienna')
folder_18.add(points[18].startPosition,'x').min(-2).max(2).step(0.001)
folder_18.add(points[18].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_18.add(points[18].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_19 = gui.addFolder('19 Texas A&M Lecture Business')
folder_19.add(points[19].startPosition,'x').min(-2).max(2).step(0.001)
folder_19.add(points[19].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_19.add(points[19].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_20 = gui.addFolder('20 Supernova Conference Antwerp')
folder_20.add(points[20].startPosition,'x').min(-2).max(2).step(0.001)
folder_20.add(points[20].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_20.add(points[20].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_21 = gui.addFolder('21 London Design Festival')
folder_21.add(points[21].startPosition,'x').min(-2).max(2).step(0.001)
folder_21.add(points[21].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_21.add(points[21].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_22 = gui.addFolder('22 Exhibition Bredaphoto')
folder_22.add(points[22].startPosition,'x').min(-2).max(2).step(0.001)
folder_22.add(points[22].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_22.add(points[22].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_23 = gui.addFolder('23 Parsons School of Design ')
folder_23.add(points[23].startPosition,'x').min(-2).max(2).step(0.001)
folder_23.add(points[23].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_23.add(points[23].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_24 = gui.addFolder('24 New York Biodesign')
folder_24.add(points[24].startPosition,'x').min(-2).max(2).step(0.001)
folder_24.add(points[24].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_24.add(points[24].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_25 = gui.addFolder('25 London sketchbook festival')
folder_25.add(points[25].startPosition,'x').min(-2).max(2).step(0.001)
folder_25.add(points[25].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_25.add(points[25].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_26 = gui.addFolder('26 Dodenaus film')
folder_26.add(points[26].startPosition,'x').min(-2).max(2).step(0.001)
folder_26.add(points[26].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_26.add(points[26].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_27 = gui.addFolder('27 Het Steen')
folder_27.add(points[27].startPosition,'x').min(-2).max(2).step(0.001)
folder_27.add(points[27].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_27.add(points[27].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_28 = gui.addFolder('28 Nebraska Art')
folder_28.add(points[28].startPosition,'x').min(-2).max(2).step(0.001)
folder_28.add(points[28].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_28.add(points[28].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_29 = gui.addFolder('29 Ugly, Stupid, Honest')
folder_29.add(points[29].startPosition,'x').min(-2).max(2).step(0.001)
folder_29.add(points[29].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_29.add(points[29].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_30 = gui.addFolder('30 hyper chunk')
folder_30.add(points[30].startPosition,'x').min(-2).max(2).step(0.001)
folder_30.add(points[30].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_30.add(points[30].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_31 = gui.addFolder('31 Copepod')
folder_31.add(points[31].startPosition,'x').min(-2).max(2).step(0.001)
folder_31.add(points[31].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_31.add(points[31].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_32 = gui.addFolder('32 Skizoid')
folder_32.add(points[32].startPosition,'x').min(-2).max(2).step(0.001)
folder_32.add(points[32].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_32.add(points[32].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_33 = gui.addFolder('33 lA page')
folder_33.add(points[33].startPosition,'x').min(-2).max(2).step(0.001)
folder_33.add(points[33].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_33.add(points[33].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_34 = gui.addFolder('34 Synesthesia')
folder_34.add(points[34].startPosition,'x').min(-2).max(2).step(0.001)
folder_34.add(points[34].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_34.add(points[34].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_35 = gui.addFolder('35 part of multiple studio reviews')
folder_35.add(points[35].startPosition,'x').min(-2).max(2).step(0.001)
folder_35.add(points[35].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_35.add(points[35].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_36 = gui.addFolder('36 Part of SEAD network:')
folder_36.add(points[36].startPosition,'x').min(-2).max(2).step(0.001)
folder_36.add(points[36].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_36.add(points[36].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_37 = gui.addFolder('37 Start of my own studio')
folder_37.add(points[37].startPosition,'x').min(-2).max(2).step(0.001)
folder_37.add(points[37].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_37.add(points[37].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_38 = gui.addFolder('38 Certificate Business management')
folder_38.add(points[38].startPosition,'x').min(-2).max(2).step(0.001)
folder_38.add(points[38].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_38.add(points[38].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_39 = gui.addFolder('39 Adobe certificate')
folder_39.add(points[39].startPosition,'x').min(-2).max(2).step(0.001)
folder_39.add(points[39].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_39.add(points[39].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_40 = gui.addFolder('40 earth ')
folder_40.add(points[40].startPosition,'x').min(-2).max(2).step(0.001)
folder_40.add(points[40].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_40.add(points[40].startPosition,'z').min(0).max(Math.PI*2).step(0.001)


const folder_41 = gui.addFolder('41 dodenaus ')
folder_41.add(points[41].startPosition,'x').min(-2).max(2).step(0.001)
folder_41.add(points[41].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_41.add(points[41].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_42 = gui.addFolder('42 dodenaus ')
folder_42.add(points[42].startPosition,'x').min(-2).max(2).step(0.001)
folder_42.add(points[42].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_42.add(points[42].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_43 = gui.addFolder('43 uglyshonest ')
folder_43.add(points[43].startPosition,'x').min(-2).max(2).step(0.001)
folder_43.add(points[43].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_43.add(points[43].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_44 = gui.addFolder('44 uglyshonest ')
folder_44.add(points[44].startPosition,'x').min(-2).max(2).step(0.001)
folder_44.add(points[44].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_44.add(points[44].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_45 = gui.addFolder('45 uglyshonest ')
folder_45.add(points[45].startPosition,'x').min(-2).max(2).step(0.001)
folder_45.add(points[45].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_45.add(points[45].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_46 = gui.addFolder('46 h chunk ')
folder_46.add(points[46].startPosition,'x').min(-2).max(2).step(0.001)
folder_46.add(points[46].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_46.add(points[46].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_47 = gui.addFolder('47 h chunk ')
folder_47.add(points[47].startPosition,'x').min(-2).max(2).step(0.001)
folder_47.add(points[47].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_47.add(points[47].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_48 = gui.addFolder('48 copepod ')
folder_48.add(points[48].startPosition,'x').min(-2).max(2).step(0.001)
folder_48.add(points[48].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_48.add(points[48].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_49 = gui.addFolder('49 copepod ')
folder_49.add(points[49].startPosition,'x').min(-2).max(2).step(0.001)
folder_49.add(points[49].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_49.add(points[49].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_50 = gui.addFolder('50 copepod ')
folder_50.add(points[50].startPosition,'x').min(-2).max(2).step(0.001)
folder_50.add(points[50].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_50.add(points[50].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_51 = gui.addFolder('51 copepod ')
folder_51.add(points[51].startPosition,'x').min(-2).max(2).step(0.001)
folder_51.add(points[51].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_51.add(points[51].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_52 = gui.addFolder('52 copepod ')
folder_52.add(points[52].startPosition,'x').min(-2).max(2).step(0.001)
folder_52.add(points[52].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_52.add(points[52].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_53 = gui.addFolder('53 skizoid ')
folder_53.add(points[53].startPosition,'x').min(-2).max(2).step(0.001)
folder_53.add(points[53].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_53.add(points[53].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_54 = gui.addFolder('54 skizoid ')
folder_54.add(points[54].startPosition,'x').min(-2).max(2).step(0.001)
folder_54.add(points[54].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_54.add(points[54].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_55 = gui.addFolder('55 syneshesia ')
folder_55.add(points[55].startPosition,'x').min(-2).max(2).step(0.001)
folder_55.add(points[55].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_55.add(points[55].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_56 = gui.addFolder('56 syneshesia ')
folder_56.add(points[56].startPosition,'x').min(-2).max(2).step(0.001)
folder_56.add(points[56].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_56.add(points[56].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_57 = gui.addFolder('57 studio review ')
folder_57.add(points[57].startPosition,'x').min(-2).max(2).step(0.001)
folder_57.add(points[57].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_57.add(points[57].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_58 = gui.addFolder('58 studio review ')
folder_58.add(points[58].startPosition,'x').min(-2).max(2).step(0.001)
folder_58.add(points[58].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_58.add(points[58].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_59 = gui.addFolder('59 studio review ')
folder_59.add(points[59].startPosition,'x').min(-2).max(2).step(0.001)
folder_59.add(points[59].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_59.add(points[59].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_60 = gui.addFolder('60 studio review ')
folder_60.add(points[60].startPosition,'x').min(-2).max(2).step(0.001)
folder_60.add(points[60].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_60.add(points[60].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_61 = gui.addFolder('61 studio review ')
folder_61.add(points[61].startPosition,'x').min(-2).max(2).step(0.001)
folder_61.add(points[61].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_61.add(points[61].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_62 = gui.addFolder('62 studio review ')
folder_62.add(points[62].startPosition,'x').min(-2).max(2).step(0.001)
folder_62.add(points[62].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_62.add(points[62].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_63 = gui.addFolder('63 fieldstation ')
folder_63.add(points[63].startPosition,'x').min(-2).max(2).step(0.001)
folder_63.add(points[63].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_63.add(points[63].startPosition,'z').min(0).max(Math.PI*2).step(0.001)

const folder_64 = gui.addFolder('64 nano ')
folder_64.add(points[64].startPosition,'x').min(-2).max(2).step(0.001)
folder_64.add(points[64].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_64.add(points[64].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_65 = gui.addFolder('65 nano ')
folder_65.add(points[65].startPosition,'x').min(-2).max(2).step(0.001)
folder_65.add(points[65].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_65.add(points[65].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_66 = gui.addFolder('66 EAS ')
folder_66.add(points[66].startPosition,'x').min(-2).max(2).step(0.001)
folder_66.add(points[66].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_66.add(points[66].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_67 = gui.addFolder('67 EAS ')
folder_67.add(points[67].startPosition,'x').min(-2).max(2).step(0.001)
folder_67.add(points[67].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_67.add(points[67].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_68 = gui.addFolder('68 EAS ')
folder_68.add(points[68].startPosition,'x').min(-2).max(2).step(0.001)
folder_68.add(points[68].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_68.add(points[68].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_69 = gui.addFolder('69 sui generis ')
folder_69.add(points[69].startPosition,'x').min(-2).max(2).step(0.001)
folder_69.add(points[69].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_69.add(points[69].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_70 = gui.addFolder('70 protozoa ')
folder_70.add(points[70].startPosition,'x').min(-2).max(2).step(0.001)
folder_70.add(points[70].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_70.add(points[70].startPosition,'z').min(0).max(Math.PI*2).step(0.001)
const folder_71 = gui.addFolder('71 alien ')
folder_71.add(points[71].startPosition,'x').min(-2).max(2).step(0.001)
folder_71.add(points[71].startPosition,'y').min(0).max(Math.PI*2).step(0.001)
folder_71.add(points[71].startPosition,'z').min(0).max(500).step(0.001)





/**
 * ! Lights
 */
//add three lightsources
const ambient_light = new THREE.AmbientLight('white', 0.05)

const directional_light = new THREE.SpotLight("#FFFDE6", 0.85)
directional_light.position.set(1,1.6,-0.4)

const directional_light_bottom = new THREE.SpotLight("white", 0.5)
directional_light_bottom.position.set(-6,-2,-19)


const spot_light = new THREE.SpotLight('white', 0.5)
spot_light.angle = 30 * (Math.PI/180)
spot_light.position.set(0.8,1.6,1.62)


const rect_light = new THREE.RectAreaLight('white', 1, 1,1)
rect_light.position.set(-1,-1.5,0)



//add the lights to the scene
scene.add(directional_light, ambient_light, spot_light,directional_light_bottom)




/**
 * ! functions
 */
//sizes -fullscreen
const sizes= {
    width: window.innerWidth,
    height: window.innerHeight
}

//resize function
window.addEventListener('resize', ()=> {
    //update sizes
    
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    //update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    //update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))

})



/**
 * ! camera
 */
//add camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height )
camera.position.z = 4

scene.add(camera)



//add renderer
const canvas = document.querySelector('.webgl')
const renderer = new THREE.WebGLRenderer({
    alpha: true,
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)

renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
// renderer.physicallyCorrectLights = true
// renderer.outputEncoding = sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure=2.6





/**
 * ! add navigation controls
 */
const controls = new OrbitControls(camera, canvas)
controls.target.set(0,0.1,0)
controls.enableDamping = false


//clock
const clock = new THREE.Clock()



/**
* ! check for mobile
*/

// let rotationSpeed = 0.025;

const isMobile = window.matchMedia("only screen and (max-width: 1000px)").matches;
if (isMobile) {
    
    console.log("is mobile")
    controls.enabled = false
    
    var rotationSpeed = 0.1
  
}
else{
    console.log("is NOT mobile")
    
    var rotationSpeed = 0.025
}

//animations
const tick = () =>
{
    
    //update fps counter
    // stats.begin()

    const elapsedTime = clock.getElapsedTime()

    //set a constant rotationspeed for the animation
    

    //update controls
    controls.update()

    //add a rotation to the mesh geometry
    sphere_geo.rotation.x = elapsedTime*rotationSpeed  
    outer_sphere_geo.rotation.x = elapsedTime*rotationSpeed

    // stats.end()

    //go through each point and apply a rotation and a raycaster
    
    const isMobile_POI = window.matchMedia("only screen and (min-width: 1000px)").matches;
    if (isMobile_POI) {
        
        console.log("is mobile_POI")
            for(const point of points)
        {
        
        
        
        point.position.set(
            point.startPosition.x 
            
            * point.startPosition.z
            ,
            (Math.cos(elapsedTime * rotationSpeed + point.startPosition.y))
            * point.startPosition.z
            ,
            (Math.sin(elapsedTime * rotationSpeed + point.startPosition.y))
            * point.startPosition.z
            )

        const screenPosition = point.position.clone()

            
        screenPosition.project(camera)

        raycaster.setFromCamera(screenPosition, camera)
        const intersects = raycaster.intersectObjects(scene.children, true)

        if(intersects.length === 0){
            point.element.classList.add('visible')
            }
        else{
            const intersectionDistance = intersects[0].distance
            const pointDistance = point.position.distanceTo(camera.position)
            

            if( intersectionDistance < pointDistance  ){
                point.element.classList.remove('visible')
            }

            else{point.element.classList.add('visible')}
        }

        const translateX = screenPosition.x * sizes.width * 0.5
        const translateY = -screenPosition.y * sizes.height * 0.5
        point.element.style.transform = `translate(${translateX}px,${translateY}px)`
        
        
        }
      
    }
    
    
    

   
    //render
    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)
}
tick()

